import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private viewPort: ViewportScroller) { }

  ngOnInit(): void {
  }
  public scrollTo(id) {
    this.viewPort.scrollToAnchor(id);
  }
}
