

<app-header></app-header>
<div class="page-title-area">
  <div class="section__bg"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="page-title-content text-center">
          <h3 class="title">About Us</h3>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">About Us</li>
            </ol>
          </nav>
          <div class="scroll-to-down">
            <a href="#portfolio-scroll"><i class="fal fa-arrow-down"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="about-us-area pt-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="about-content">
          
          <span>About us</span>
          <h3 class="title">We create mind blowing </h3>
          <p>Sitting proudly atop is the two-storey penthouse. The nati master bedroom suite is phenomenally spacious
            makes the most of a its unrivalled position.</p>
          <ul>
            <li>Graphic design template</li>
            <li>Motion ui design</li>
            <li>Theme template design</li>
            <li>User interface design</li>
          </ul>
          <a class="main-btn" href="#">Learn More</a>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="about-thumb animated wow fadeInRight" data-wow-duration="3000ms" data-wow-delay="0ms">
          <img src="assets/images/about-thumb.png" alt="">
          <ul>
            <li>
              <img src="assets/images/about-brand-1.png" alt="">
              <span>Top Rated</span>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </li>
            <li>
              <img src="assets/images/about-brand-2.png" alt="">
              <span>Our Rated</span>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </li>
            <li>
              <img src="assets/images/about-brand-3.png" alt="">
              <span>Our Rated 5 of 5</span>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<div id="portfolio-scroll" class="portfolio-style-1-area pt-70">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-9">
        <div class="section-title text-center">
          
          <h3 class="title">Our Project</h3>
          <p>Our focus is on delivering you the absolute best support guiding you through training and providing.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="project-5-item mt-30">
          <img src="assets/images/project/project-1.png" alt="">
          <div class="project-overlay">
            <h3 class="title">Digital Product Design</h3>
            <span>Artist, Illustration</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="project-5-item mt-30">
          <img src="assets/images/project/project-2.png" alt="">
          <div class="project-overlay">
            <h3 class="title">Digital Product</h3>
            <span>Artist, Illustration</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="project-5-item mt-30">
          <img src="assets/images/project/project-3.png" alt="">
          <div class="project-overlay">
            <h3 class="title">Digital Product </h3>
            <span>Artist, Illustration</span>
          </div>
        </div>
        <div class="project-5-item mt-30">
          <img src="assets/images/project/project-4.png" alt="">
          <div class="project-overlay">
            <h3 class="title">Digital Product </h3>
            <span>Artist, Illustration</span>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="project-5-item mt-30">
          <img src="assets/images/project/project-5.png" alt="">
          <div class="project-overlay">
            <h3 class="title">Digital Product Design</h3>
            <span>Artist, Illustration</span>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="project-5-item mt-30">
              <img src="assets/images/project/project-6.png" alt="">
              <div class="project-overlay">
                <h3 class="title">Digital Product</h3>
                <span>Artist, Illustration</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="project-5-item mt-30">
              <img src="assets/images/project/project-7.png" alt="">
              <div class="project-overlay">
                <h3 class="title">Digital Product </h3>
                <span>Artist, Illustration</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="project-5-item mt-30">
          <img src="assets/images/project/project-8.png" alt="">
          <div class="project-overlay">
            <h3 class="title">Digital Product Design</h3>
            <span>Artist, Illustration</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="testimonial-area mt-100">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-9">
        <div class="section-title section-title-2 text-center">
          
          <h3 class="title">Testimonial</h3>
          <p>Our focus is on delivering you the absolute best support guiding you through training and providing.</p>
        </div>
      </div>
    </div>
    <div class="row testimonial-active">
      <div class="col-lg-4">
        <div class="testimonial-item mt-30">
          <div class="quote">
            <svg xmlns="http://www.w3.org/2000/svg" width="53.729" height="40" viewBox="0 0 53.729 40">
              <g data-name="Group 21" transform="translate(0 0)">
                <path data-name="Union 1"
                  d="M47.055,40a1.21,1.21,0,0,1-1.018-.509L31.106,19.357A12.178,12.178,0,0,1,29.07,11.1,12.364,12.364,0,1,1,45.98,23.881l6.957,14.253A1.313,1.313,0,0,1,51.806,40ZM18.1,40a1.209,1.209,0,0,1-1.018-.509L2.149,19.357A12.77,12.77,0,0,1,.056,11.043,12.395,12.395,0,1,1,17.023,23.881L23.98,38.134A1.313,1.313,0,0,1,22.849,40Z"
                  transform="translate(0 0)" />
              </g>
            </svg>
          </div>
          <p>There are many variations from into passages of Lorem Ipsum available, but the majority have suffered
            alteration in some form, by injected humour, or randomised words which don’t look even slightly believable.
          </p>
          <div class="info">
            <img src="assets/images/testimonial-user.png" alt="">
            <h5 class="title">Abdur Rohman</h5>
            <span>Sr. Product designer</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="testimonial-item mt-30">
          <div class="quote">
            <svg xmlns="http://www.w3.org/2000/svg" width="53.729" height="40" viewBox="0 0 53.729 40">
              <g data-name="Group 21" transform="translate(0 0)">
                <path data-name="Union 1"
                  d="M47.055,40a1.21,1.21,0,0,1-1.018-.509L31.106,19.357A12.178,12.178,0,0,1,29.07,11.1,12.364,12.364,0,1,1,45.98,23.881l6.957,14.253A1.313,1.313,0,0,1,51.806,40ZM18.1,40a1.209,1.209,0,0,1-1.018-.509L2.149,19.357A12.77,12.77,0,0,1,.056,11.043,12.395,12.395,0,1,1,17.023,23.881L23.98,38.134A1.313,1.313,0,0,1,22.849,40Z"
                  transform="translate(0 0)" />
              </g>
            </svg>
          </div>
          <p>There are many variations from into passages of Lorem Ipsum available, but the majority have suffered
            alteration in some form, by injected humour, or randomised words which don’t look even slightly believable.
          </p>
          <div class="info">
            <img src="assets/images/testimonial-user.png" alt="">
            <h5 class="title">Mehedi Hasan</h5>
            <span>Sr. Product designer</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="testimonial-item mt-30">
          <div class="quote">
            <svg xmlns="http://www.w3.org/2000/svg" width="53.729" height="40" viewBox="0 0 53.729 40">
              <g data-name="Group 21" transform="translate(0 0)">
                <path data-name="Union 1"
                  d="M47.055,40a1.21,1.21,0,0,1-1.018-.509L31.106,19.357A12.178,12.178,0,0,1,29.07,11.1,12.364,12.364,0,1,1,45.98,23.881l6.957,14.253A1.313,1.313,0,0,1,51.806,40ZM18.1,40a1.209,1.209,0,0,1-1.018-.509L2.149,19.357A12.77,12.77,0,0,1,.056,11.043,12.395,12.395,0,1,1,17.023,23.881L23.98,38.134A1.313,1.313,0,0,1,22.849,40Z"
                  transform="translate(0 0)" />
              </g>
            </svg>
          </div>
          <p>There are many variations from into passages of Lorem Ipsum available, but the majority have suffered
            alteration in some form, by injected humour, or randomised words which don’t look even slightly believable.
          </p>
          <div class="info">
            <img src="assets/images/testimonial-user.png" alt="">
            <h5 class="title">Mosharof Hossain</h5>
            <span>Sr. Product designer</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="testimonial-item mt-30">
          <div class="quote">
            <svg xmlns="http://www.w3.org/2000/svg" width="53.729" height="40" viewBox="0 0 53.729 40">
              <g data-name="Group 21" transform="translate(0 0)">
                <path data-name="Union 1"
                  d="M47.055,40a1.21,1.21,0,0,1-1.018-.509L31.106,19.357A12.178,12.178,0,0,1,29.07,11.1,12.364,12.364,0,1,1,45.98,23.881l6.957,14.253A1.313,1.313,0,0,1,51.806,40ZM18.1,40a1.209,1.209,0,0,1-1.018-.509L2.149,19.357A12.77,12.77,0,0,1,.056,11.043,12.395,12.395,0,1,1,17.023,23.881L23.98,38.134A1.313,1.313,0,0,1,22.849,40Z"
                  transform="translate(0 0)" />
              </g>
            </svg>
          </div>
          <p>There are many variations from into passages of Lorem Ipsum available, but the majority have suffered
            alteration in some form, by injected humour, or randomised words which don’t look even slightly believable.
          </p>
          <div class="info">
            <img src="assets/images/testimonial-user.png" alt="">
            <h5 class="title">Rayhan Uddin</h5>
            <span>Sr. Product designer</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="testimonial-item mt-30">
          <div class="quote">
            <svg xmlns="http://www.w3.org/2000/svg" width="53.729" height="40" viewBox="0 0 53.729 40">
              <g data-name="Group 21" transform="translate(0 0)">
                <path data-name="Union 1"
                  d="M47.055,40a1.21,1.21,0,0,1-1.018-.509L31.106,19.357A12.178,12.178,0,0,1,29.07,11.1,12.364,12.364,0,1,1,45.98,23.881l6.957,14.253A1.313,1.313,0,0,1,51.806,40ZM18.1,40a1.209,1.209,0,0,1-1.018-.509L2.149,19.357A12.77,12.77,0,0,1,.056,11.043,12.395,12.395,0,1,1,17.023,23.881L23.98,38.134A1.313,1.313,0,0,1,22.849,40Z"
                  transform="translate(0 0)" />
              </g>
            </svg>
          </div>
          <p>There are many variations from into passages of Lorem Ipsum available, but the majority have suffered
            alteration in some form, by injected humour, or randomised words which don’t look even slightly believable.
          </p>
          <div class="info">
            <img src="assets/images/testimonial-user.png" alt="">
            <h5 class="title">Imran Ahmed</h5>
            <span>Sr. Product designer</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="team-area">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-9">
        <div class="section-title text-center">
          
          <h3 class="title">Leadership</h3>
          <p>Take a peek at whats behind Appha Labs.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row team-active">
      <div class="col-lg-3">
        <div class="team-item mt-40 mb-100">
          <img src="assets/images/team-1.jpg" alt="">
          <div class="team-overlay d-flex align-items-end">
            <div class="content">
              <h4 class="title">Michael Quaynor</h4>
              <span>Founding Partner | Software Development</span>
              <ul>
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="team-item mt-40 mb-100">
          <img src="assets/images/team-2.jpg" alt="">
          <div class="team-overlay d-flex align-items-end">
            <div class="content">
              <h4 class="title">Bright Dawu</h4>
              <span>Founding Partner | Business Development</span>
              <ul>
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="team-item mt-40 mb-100">
          <img src="assets/images/team-3.jpg" alt="">
          <div class="team-overlay d-flex align-items-end">
            <div class="content">
              <h4 class="title">Kofi Akyeamong Agyapong</h4>
              <span>Founding Partner | Software Development</span>
              <ul>
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="team-item mt-40 mb-100">
          <img src="assets/images/team-4.jpg" alt="">
          <div class="team-overlay d-flex align-items-end">
            <div class="content">
              <h4 class="title">Hamza Osman</h4>
              <span>Founding Partner | Partnerships</span>
              <ul>
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="team-item mt-40 mb-100">
          <img src="assets/images/team-5.jpg" alt="">
          <div class="team-overlay d-flex align-items-end">
            <div class="content">
              <h4 class="title">Charlotte Emma</h4>
              <span>Product Designer</span>
              <ul>
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="team-item mt-40 mb-100">
          <img src="assets/images/team-2.jpg" alt="">
          <div class="team-overlay d-flex align-items-end">
            <div class="content">
              <h4 class="title">Charlotte Emma</h4>
              <span>Product Designer</span>
              <ul>
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
