<header class="header-area">
  <div class="header-nav">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="navigation">
            <nav class="navbar navbar-expand-lg navbar-light ">
              <a class="navbar-brand" href="index.html"><img src="assets/images/apphalabs_logo.png" width="100px" alt=""></a>
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
              </button> 
              <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                  <li class="nav-item active">
                    <a class="nav-link" (click)="scrollTo('home')">Home</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" (click)="scrollTo('aboutus')">About </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" (click)="scrollTo('services')">Services </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" (click)="scrollTo('portfolio')">Portfolio </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" (click)="scrollTo('contact')">Contact</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>