<footer class="footer-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="footer-top d-block d-sm-flex  justify-content-between align-items-center">
          <div class="footer-logo">
            <a href="#"><img src="assets/images/apphalabs_logo.png" alt="" width="100px"></a>
          </div>
          <div class="footer-social">
            <ul>
              <li><a target="_blank" href="https://twitter.com/apphalabs"><i class="fab fa-facebook-f"></i></a></li>
              <li><a target="_blank" href="https://twitter.com/apphalabs"><i class="fab fa-twitter"></i></a></li>
              <li><a target="_blank" href="https://www.instagram.com/apphalabs/"><i class="fab fa-youtube"></i></a></li>
              <li><a target="_blank" href="https://www.instagram.com/apphalabs/"><i class="fab fa-instagram"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-item">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="footer-about mt-30">
            <h4 class="title">Company</h4>
            <p>At Appha Labs, we bring empathy, collaboration, and shared passion to all of our works.
              We help businessses and communities align technology to their future.</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="footer-list mt-30 ml-95">
            <h4 class="title">Quick links</h4>
            <ul>
              <li><a (click)="scrollTo('aboutus')">About us</a></li>
              <li><a (click)="scrollTo('services')">Services</a></li>
              <li><a (click)="scrollTo('portfolio')">Portofolio</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="footer-list mt-30">
            <h4 class="title">Categories</h4>
            <ul>
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Terms & Conditions</a></li>
              <li><a (click)="scrollTo('news')">News & Articles</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-info mt-30">
            <h3 class="title">Contact Us</h3>
            <ul>
              <li><i class="fal fa-map-marker-alt"></i> V324 George Bush Hwy
                McCarthy Hill Junction - Accra </li>
              <li><i class="fal fa-phone"></i> 0302 984 047 </li>
              <li><i class="fal fa-envelope"></i> hello@apphalabs.com </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="footer-copyright">
          <p>Copyright @ 2022. Appha Labs.</p>
        </div>
      </div>
    </div>
  </div>
</footer>
<a class="back-to-top">
  <i class="fal fa-angle-up"></i>
</a>