<app-header></app-header>
    <section class="hero-area" id="home">
        <div class="section__bg"></div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="hero-content">
                        <h1 class="title animated wow fadeInLeft" data-wow-duration="3000ms" data-wow-delay="0ms">What's Your Technology need?</h1>
                        <span class="animated wow fadeInLeft" data-wow-duration="3000ms" data-wow-delay="300ms">We provide our clients with expert guidance and build solutions vital to their organizations.</span>                        
                        <a class="main-btn main-btn-2 animated wow fadeInDown" data-wow-duration="3000ms" data-wow-delay="600ms" href="tel:0302984047">Free Strategy Call</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="sub-item-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 col-sm-8">
                    <div class="sub-item">
                        <div class="icon">
                            <img src="assets/images/home_icons/app.png" alt="">
                        </div>
                        <h3 class="title">Software Development</h3>
                        <span>We have the technology and industry expertise to solutions.</span>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-8">
                    <div class="sub-item">
                        <div class="icon">
                            <img src="assets/images/home_icons/web-development.png" alt="">
                        </div>
                        <h3 class="title">Web Development</h3>
                        <span>Custom web application development by our expert web app developers.</span>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-8">
                    <div class="sub-item">
                        <div class="icon">
                            <img src="assets/images/home_icons/design.png" alt="">
                        </div>
                        <h3 class="title">UI/UX Design</h3>
                        <span>Creative direction, user experience, user interface & visual design.</span>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-8">
                    <div class="sub-item">
                        <div class="icon">
                            <img src="assets/images/home_icons/support.png" alt="">
                        </div>
                        <h3 class="title">Technology Consulting</h3>
                        <span>We specialize at working where business and technology meet.</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="about-us-area pb-100" id="aboutus">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="about-content">
                        <span>About us</span>
                        <h3 class="title">We empower our clients with technology.</h3>
                        <p>Appha Labs is a software development and technology consultancy company based in Accra. 
                            We are a team of programmers, designers, digital strategists, communicators, and thought 
                            leaders  that apply our passion for technology to create successful products and solutions 
                            for our clients. Our approach is fast, flexible, and collaborative. We start with a consultative 
                            approach grounded in informed understanding and common cause. We take the time to understand your 
                            company and sector, your users, and your hopes and needs to come up with a customized solution 
                            that’s the right fit for your organization, using best-in-class techniques such as design thinking,
                             rapid prototyping and user exeprience design. Our clients have direct access to our leadership team,
                              plus a designated Product Manager who is responsible for making sure we thoroughly understand client 
                              expectations and deliver to them. Our uniqueness lies in our excellent delivery, quality assurance 
                              process and expertise. Our ultimate goal is creating experiences that ensure 100% satisfaction f
                              or our clients and foster their business growth.

                        </p>
                        
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="about-thumb animated wow fadeInRight" data-wow-duration="3000ms" data-wow-delay="0ms">
                        <img src="assets/images/office.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="service-area pb-100" id="services">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-9">
                    <div class="section-title text-center">
                        <h3 class="title">Our Services</h3>
                        <p>We leverage advanced technology to deliver solutions that exceend expecations.</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-8">
                    <div class="service-item mt-30 animated wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="0ms">
                        <div class="icon">
                            <img src="assets/images/home_icons/tablet.png" alt="">
                        </div>
                        <h3 class="title">Business Software Developemt</h3>
                        <p>Enhancing business agility and efficiency with cutting-edge web and mobile solutions.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-8">
                    <div class="service-item mt-30 animated wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="300ms">
                        <div class="icon">
                            <img src="assets/images/home_icons/app.png" alt="">
                        </div>
                        <h3 class="title">Mobile App Development</h3>
                        <p>We build innovative iOS and Android applications for a variety of industries and use-cases.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-8">
                    <div class="service-item mt-30 animated wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <img src="assets/images/home_icons/web-development.png" alt="">
                        </div>
                        <h3 class="title">Web Development</h3>
                        <p>Front-end & design, backend, database, and server scale designed to fit your custom workflow.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-8">
                    <div class="service-item mt-30 animated wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="0ms">
                        <div class="icon">
                            <img src="assets/images/home_icons/design.png" alt="">
                        </div>
                        <h3 class="title">UI/UX Design</h3>
                        <p>Intuitive & user-centric designs that find efficiencies and build your competitive edge.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-8">
                    <div class="service-item mt-30 animated wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="300ms">
                       <div class="icon">
                           <img src="assets/images/home_icons/marketing.png" alt="">
                       </div>
                        <h3 class="title">Digital Marketing</h3>
                        <p>Social media campaigns, email campaigns, paid media, SEO, content marketing, analytics.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-8">
                    <div class="service-item mt-30 animated wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <img src="assets/images/home_icons/support.png" alt="">
                        </div>
                        <h3 class="title">Technology Consulting</h3>
                        <p>Trusted partner to help determine the right technology investment to drive your business.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-8">
                    <div class="service-item mt-30 animated wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <img src="assets/images/home_icons/idea.png" alt="">
                        </div>
                        <h3 class="title">Ideation and Strategy</h3>
                        <p>All ideas start with a goal, to ensure you reach that goal there needs to be a clear strategy.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-8">
                    <div class="service-item mt-30 animated wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <img src="assets/images/home_icons/computer.png" alt="">
                        </div>
                        <h3 class="title">Software Testing</h3>
                        <p>Our testing services encompass various approaches to ascertain that your product is scalable, reliable, and secure.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-8">
                    <div class="service-item mt-30 animated wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <img src="assets/images/home_icons/statistics.png" alt="">
                        </div>
                        <h3 class="title">Support/Maintenance</h3>
                        <p>Dedicated software maintenance and support services that will enable you to concentrate on your core business functions.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="portfolio-area pb-130" id="portfolio">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-9">
                    <div class="section-title text-center">
                        <h3 class="title">Our Portfolio</h3>
                        <p>Here's some of our mobile applications and passion projects.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid p-0">
            <div class="row portfolio-active">
                <div class="col-lg-3">
                    <div class="portfolio-item mt-30">
                        <img src="assets/images/1.png" alt="">
                        <div class="portfolio-overlay">
                            <div class="content">
                                <h4 class="title">EjumaHub</h4>
                                <p>Sitting proudly atop is the storey form bedroom phenomenally.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="portfolio-item mt-30 mb-30">
                        <img src="assets/images/2.png" alt="">
                        <div class="portfolio-overlay">
                            <div class="content">
                                <h4 class="title">BeHale App</h4>
                                <p>Sitting proudly atop is the storey form bedroom phenomenally.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="portfolio-item mt-30 mb-30">
                        <img src="assets/images/3.png" alt="">
                        <div class="portfolio-overlay">
                            <div class="content">
                                <h4 class="title">Dyffa</h4>
                                <p>Sitting proudly atop is the storey form bedroom phenomenally.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="portfolio-item mt-30 mb-30">
                        <img src="assets/images/4.png" alt="">
                        <div class="portfolio-overlay">
                            <div class="content">
                                <h4 class="title">SoccaSnap</h4>
                                <p>Sitting proudly atop is the storey form bedroom phenomenally.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="article-area" id="news">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-9">
                    <div class="section-title text-center">
                        <h3 class="title">Latest Articles & News</h3>
                    </div>
                </div>
            </div>
            <div class="row justify-content-start">
                <div class="col-lg-4 col-md-6 col-sm-9">
                    <div class="article-item mt-30">
                        <div class="article-top text-center">
                            <a target="_blank" href="https://bit.ly/3m8HG7f"><span>BeHale App – The Ghanaian-Built app providing relevant..</span></a>
                        </div>
                        <div class="article-thumb">
                            <a target="_blank" href="https://bit.ly/3m8HG7f"><img src="assets/images/behaleapp.png" alt=""></a>
                            <div class="date">
                                <h5 class="title">20</h5>
                                <span>JUN</span>
                            </div>
                        </div>
                        <div class="article-content pl-25 pr-25 pt-25">
                            <p>Following the unfortunate emergence of the Novel Coronavirus (COVID-19) that has...</p>
                            <a target="_blank" href="https://bit.ly/3m8HG7f">READ MORE</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-9">
                    <div class="article-item mt-30">
                        <div class="article-top text-center">
                            <a target="_blank" href="https://bit.ly/35vsATk"><span>BeHale: The simple, secure, all Inclusive COVID-19 app </span></a>
                        </div>
                        <div class="article-thumb">
                            <a target="_blank" href="https://bit.ly/35vsATk"><img src="assets/images/behale.png" alt=""></a>
                            <div class="date">
                                <h5 class="title">13</h5>
                                <span>MAY</span>
                            </div>
                        </div>
                        <div class="article-content pl-25 pr-25 pt-25">
                            <p>BeHale Inc. is an mHealth startup based, focused on building Web and Mobile App...</p>
                            <a target="_blank" href="https://bit.ly/35vsATk">READ MORE</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
 <app-footer id="contact"></app-footer>