
<app-header></app-header>
<div class="page-title-area">
  <div class="section__bg"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="page-title-content text-center">
          <h3 class="title">Portfolio</h3>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Portfolio</li>
            </ol>
          </nav>
          <div class="scroll-to-down">
            <a href="#portfolio-scroll"><i class="fal fa-arrow-down"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="portfolio-style-4-area">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 col-md-9">
        <div class="section-title text-center">
          <h3 class="title">Our Portfolio</h3>
          <div class="project-menu">
            <ul>
              <li data-filter="*" class="active">Graphic Design</li>
              <li data-filter=".cat-1">Motion Design</li>
              <li data-filter=".cat-2">Illustration</li>
              <li data-filter=".cat-3">Product Design</li>
              <li data-filter=".cat-4">Development</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid">
      <div class="col-lg-4 col-md-6 cat-4 cat-2">
        <div class="portfolio-style-4-item mt-30">
          <div class="portfolio-thumb">
            <img src="assets/images/portfolio/portfolio-style-11.png" alt="">
            <a href="#">See details <span><img src="assets/images/icon/portfolio-arrow-2.png" alt=""></span></a>
          </div>
          <div class="portfolio-content">
            <h5 class="title">Graphic design vs product design vs graphic illustration</h5>
            <span>Illustration, Graphic Design</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 cat-1 cat-3">
        <div class="portfolio-style-4-item mt-30">
          <div class="portfolio-thumb">
            <img src="assets/images/portfolio/portfolio-style-12.png" alt="">
            <a href="#">See details <span><img src="assets/images/icon/portfolio-arrow-2.png" alt=""></span></a>
          </div>
          <div class="portfolio-content">
            <h5 class="title">Graphic design vs product design vs graphic illustration</h5>
            <span>Illustration, Graphic Design</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 cat-2 cat-3">
        <div class="portfolio-style-4-item mt-30">
          <div class="portfolio-thumb">
            <img src="assets/images/portfolio/portfolio-style-13.png" alt="">
            <a href="#">See details <span><img src="assets/images/icon/portfolio-arrow-2.png" alt=""></span></a>
          </div>
          <div class="portfolio-content">
            <h5 class="title">Graphic design vs product design vs graphic illustration</h5>
            <span>Illustration, Graphic Design</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 cat-4 cat-3 cat-2">
        <div class="portfolio-style-4-item mt-30">
          <div class="portfolio-thumb">
            <img src="assets/images/portfolio/portfolio-style-15.png" alt="">
            <a href="#">See details <span><img src="assets/images/icon/portfolio-arrow-2.png" alt=""></span></a>
          </div>
          <div class="portfolio-content">
            <h5 class="title">Graphic design vs product design vs graphic illustration</h5>
            <span>Illustration, Graphic Design</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 cat-1">
        <div class="portfolio-style-4-item mt-30">
          <div class="portfolio-thumb">
            <img src="assets/images/portfolio/portfolio-style-16.png" alt="">
            <a href="#">See details <span><img src="assets/images/icon/portfolio-arrow-2.png" alt=""></span></a>
          </div>
          <div class="portfolio-content">
            <h5 class="title">Graphic design vs product design vs graphic illustration</h5>
            <span>Illustration, Graphic Design</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 cat-2">
        <div class="portfolio-style-4-item mt-30">
          <div class="portfolio-thumb">
            <img src="assets/images/portfolio/portfolio-style-14.png" alt="">
            <a href="#">See details <span><img src="assets/images/icon/portfolio-arrow-2.png" alt=""></span></a>
          </div>
          <div class="portfolio-content">
            <h5 class="title">Graphic design vs product design vs graphic illustration</h5>
            <span>Illustration, Graphic Design</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 cat-3">
        <div class="portfolio-style-4-item mt-30">
          <div class="portfolio-thumb">
            <img src="assets/images/portfolio/portfolio-style-18.png" alt="">
            <a href="#">See details <span><img src="assets/images/icon/portfolio-arrow-2.png" alt=""></span></a>
          </div>
          <div class="portfolio-content">
            <h5 class="title">Graphic design vs product design vs graphic illustration</h5>
            <span>Illustration, Graphic Design</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 cat-4">
        <div class="portfolio-style-4-item mt-30">
          <div class="portfolio-thumb">
            <img src="assets/images/portfolio/portfolio-style-17.png" alt="">
            <a href="#">See details <span><img src="assets/images/icon/portfolio-arrow-2.png" alt=""></span></a>
          </div>
          <div class="portfolio-content">
            <h5 class="title">Graphic design vs product design vs graphic illustration</h5>
            <span>Illustration, Graphic Design</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 cat-3">
        <div class="portfolio-style-4-item mt-30">
          <div class="portfolio-thumb">
            <img src="assets/images/portfolio/portfolio-style-19.png" alt="">
            <a href="#">See details <span><img src="assets/images/icon/portfolio-arrow-2.png" alt=""></span></a>
          </div>
          <div class="portfolio-content">
            <h5 class="title">Graphic design vs product design vs graphic illustration</h5>
            <span>Illustration, Graphic Design</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
