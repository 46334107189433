<app-header></app-header>
<div class="page-title-area">
  <div class="section__bg"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="page-title-content text-center">
          <h3 class="title">Services</h3>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Services</li>
            </ol>
          </nav>
          <div class="scroll-to-down">
            <a href="#portfolio-scroll"><i class="fal fa-arrow-down"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="service-area service-page-area pb-100">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="service-item mt-30 animated wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="0ms">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="62.001" height="70" viewBox="0 0 62.001 70">
              <g data-name="Group 15" transform="translate(-270 -1139)">
                <path data-name="Union 2"
                  d="M-5,322.218.218,317l1.8,1.8-2.145,2.145H15.685V323.5H-.109l2.131,2.131-1.8,1.8Z"
                  transform="translate(275 855.366)" fill="#f92044" />
                <g data-name="Group 9" transform="translate(277.025 1139)">
                  <path class="white" data-name="Path 19"
                    d="M127.959,440.246H81.286V428.611h46.673v11.635Zm-44.165-2.551h41.657v-6.532H83.795Z"
                    transform="translate(-81.286 -370.246)" fill="#235c51" />
                  <path class="white" data-name="Path 20"
                    d="M146.328,199.2H106.764l1.088-1.873c5.87-10.114,8.973-22.572,8.973-36.027v-1.248h19.443V161.3c0,13.457,3.1,25.914,8.973,36.027Zm-35.269-2.5h30.974c-5.241-9.813-8.081-21.551-8.257-34.157H119.316C119.141,175.152,116.3,186.89,111.059,196.7Z"
                    transform="translate(-103.209 -138.284)" fill="#235c51" />
                  <path class="white" data-name="Path 21"
                    d="M151.5,19.4H132.928L124.97,0h11.275V4.835h2.316V0h7.3V4.835h2.316V0h11.276ZM134.6,16.924H149.82L155.744,2.48H150.67V7.315h-7.3V2.48h-2.315V7.315h-7.3V2.48h-5.072Z"
                    transform="translate(-118.875)" fill="#235c51" />
                  <path class="white" data-name="Path 22"
                    d="M174.109,131.565H145.3v-6.778h28.81Zm-26.329-2.314h23.846V127.1H147.781Z"
                    transform="translate(-136.367 -107.479)" fill="#235c51" />
                </g>
                <path class="white" data-name="Path 23"
                  d="M219.916,244.71a6.1,6.1,0,1,1-6.094-6.1A6.1,6.1,0,0,1,219.916,244.71Z"
                  transform="translate(86.549 932.872)" fill="#235c51" />
                <path data-name="Union 1"
                  d="M15.841,326.379l-3.41-3.408H-5v-2.551H12.421L15.841,317H26.792L22.1,321.69l4.692,4.689Zm-1.082-4.689,2.138,2.138h3.733l-2.138-2.138,2.138-2.137H16.9Z"
                  transform="translate(305.208 855.895)" fill="#f92044" />
              </g>
            </svg>
          </div>
          <h3 class="title">Business Stratagy</h3>
          <p>Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="service-item mt-30 animated wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="300ms">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="92.773" height="70" viewBox="0 0 92.773 70">
              <g data-name="003-vector" transform="translate(0 -53.596)">
                <g data-name="Group 30" transform="translate(2.93 53.596)">
                  <path class="white" data-name="Path 126"
                    d="M420.307,203.524l-1.963-1.963,1.389-1.389A20.692,20.692,0,0,0,426,188.46l2.749.393a23.391,23.391,0,0,1-7.058,13.281Z"
                    transform="translate(-348.665 -165.053)" fill="#235c51" />
                  <path class="white" data-name="Path 127"
                    d="M57.655,235.059,24.738,202.142a23.391,23.391,0,0,1-7.058-13.281l2.748-.393A20.692,20.692,0,0,0,26.7,200.18l30.955,30.952,15.68-15.68,1.963,1.963Z"
                    transform="translate(-17.541 -165.059)" fill="#235c51" />
                  <path class="white" data-name="Path 128"
                    d="M55.482,71.849a18.121,18.121,0,0,0-35.854,0l-2.748-.4a20.9,20.9,0,0,1,41.348,0Z"
                    transform="translate(-16.88 -53.596)" fill="#235c51" />
                  <path class="white" id="Path_129" data-name="Path 129"
                    d="M279.482,71.849a18.121,18.121,0,0,0-35.854,0l-2.748-.4a20.9,20.9,0,0,1,41.35,0Z"
                    transform="translate(-202.002 -53.596)" fill="#235c51" />
                </g>
                <g id="Group_31" data-name="Group 31" transform="translate(0 70.258)">
                  <rect class="white" data-name="Rectangle 66" width="3" height="4" transform="translate(42 27.338)"
                    fill="#235c51" />
                  <rect class="white" data-name="Rectangle 71" width="3" height="4" transform="translate(42 35.338)"
                    fill="#235c51" />
                  <rect class="white" data-name="Rectangle 67" width="3" height="3" transform="translate(42 19.338)"
                    fill="#235c51" />
                  <path class="white" data-name="Path 130"
                    d="M8.331,157.927H0V149.6H8.331ZM2.777,155.15H5.554v-2.777H2.777Z" transform="translate(0 -149.596)"
                    fill="#235c51" />
                  <path data-name="Union 3"
                    d="M-640.122-2454.331v-2.993H-655v-2h14.878v-3.338h8.331v3.338H-616v2h-15.791v2.993Zm2.777-2.778h2.777v-2.776h-2.777Z"
                    transform="translate(679 2462.662)" fill="#f92044" />
                  <path class="white" data-name="Path 132"
                    d="M456.331,157.927H448V149.6h8.331Zm-5.554-2.777h2.777v-2.777h-2.777Z"
                    transform="translate(-370.244 -149.596)" fill="#235c51" />
                </g>
                <path data-name="Path 133"
                  d="M342.013,287.341,330.5,256.1l31.241,11.509,4.168,1.734-18.038,4.13-4.562,17.366Zm-6.823-26.555,7.742,21.015,2.655-10.617L356.2,268.53Z"
                  transform="translate(-273.141 -167.357)" fill="#f92044" />
              </g>
            </svg>
          </div>
          <h3 class="title">UX & Product Design</h3>
          <p>Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="service-item mt-30 animated wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="79.34" height="70" viewBox="0 0 79.34 70">
              <path data-name="Path 10826"
                d="M124.784,198.044a1.3,1.3,0,0,1-.848-.313l-8.476-7.217a1.31,1.31,0,0,1,0-1.995l8.476-7.217a1.31,1.31,0,1,1,1.7,1.994l-7.3,6.22,7.3,6.22a1.31,1.31,0,0,1-.85,2.308Zm0,0"
                transform="translate(-93.481 -147.122)" fill="#f92044" />
              <path data-name="Path 10827"
                d="M251.018,198.044a1.311,1.311,0,0,1-.85-2.308l7.3-6.22-7.3-6.22a1.309,1.309,0,0,1,1.7-1.994l8.476,7.217a1.31,1.31,0,0,1,0,1.995l-8.476,7.217A1.3,1.3,0,0,1,251.018,198.044Zm0,0"
                transform="translate(-202.982 -147.122)" fill="#f92044" />
              <path data-name="Path 10828"
                d="M190.266,180.725a1.333,1.333,0,0,1-.272-.028,1.31,1.31,0,0,1-1.012-1.552l5.283-25.081a1.31,1.31,0,1,1,2.563.54l-5.283,25.081A1.31,1.31,0,0,1,190.266,180.725Zm0,0"
                transform="translate(-153.596 -124.39)" fill="#f92044" />
              <path class="white" data-name="Path 10829"
                d="M70.545,70H8.795A8.8,8.8,0,0,1,0,61.205V8.795A8.805,8.805,0,0,1,8.795,0H70.545A8.805,8.805,0,0,1,79.34,8.795v52.41A8.8,8.8,0,0,1,70.545,70ZM8.795,2.62A6.182,6.182,0,0,0,2.62,8.795v52.41A6.182,6.182,0,0,0,8.795,67.38H70.545a6.182,6.182,0,0,0,6.175-6.175V8.795A6.182,6.182,0,0,0,70.545,2.62Zm0,0"
                fill="#235c51" />
              <path class="white" data-name="Path 10830"
                d="M78.03,100.71H1.31a1.31,1.31,0,0,1,0-2.62H78.03a1.31,1.31,0,0,1,0,2.62Zm0,0"
                transform="translate(0 -79.735)" fill="#235c51" />
              <path class="white" data-name="Path 10831"
                d="M101.594,42.579a4.145,4.145,0,1,1,4.145-4.145A4.151,4.151,0,0,1,101.594,42.579Zm0-5.671a1.525,1.525,0,1,0,1.525,1.525A1.527,1.527,0,0,0,101.594,36.909Zm0,0"
                transform="translate(-79.214 -27.873)" fill="#235c51" />
              <path class="white" data-name="Path 10832"
                d="M33.532,42.365a4.145,4.145,0,1,1,4.145-4.145A4.15,4.15,0,0,1,33.532,42.365Zm0-5.671a1.525,1.525,0,1,0,1.525,1.525A1.528,1.528,0,0,0,33.532,36.694Zm0,0"
                transform="translate(-23.888 -27.698)" fill="#235c51" />
              <path class="white" data-name="Path 10833"
                d="M169.66,42.794a4.145,4.145,0,1,1,4.145-4.145A4.15,4.15,0,0,1,169.66,42.794Zm0-5.671a1.525,1.525,0,1,0,1.525,1.525A1.527,1.527,0,0,0,169.66,37.123Zm0,0"
                transform="translate(-134.544 -28.047)" fill="#235c51" />
            </svg>
          </div>
          <h3 class="title">App Development</h3>
          <p>Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="service-item mt-30 animated wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="0ms">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="56.911" height="70.537" viewBox="0 0 56.911 70.537">
              <g transform="translate(0.25 0.25)">
                <path class="white" data-name="Path 10777"
                  d="M56.033,70l3.225-19.556L53.832,32.628a22.7,22.7,0,1,1,44.162-7.392l.016,3.092,4.813,14.08H97.179l-1.2,15.051-11.975-1.1-1.638,13.28,2.519.31L86.227,59.11l12.084,1.11,1.214-15.275h6.847L100.546,27.9l-.014-2.67a25.236,25.236,0,1,0-49.114,8.18l5.239,17.207L53.528,69.588Z"
                  transform="translate(-50.06)" fill="#235c51" stroke-width="0.5" />
                <path data-name="Path 10778"
                  d="M151.369,66.148a5.5,5.5,0,0,1,7.479-7.484,5.5,5.5,0,0,1,10.586,0,5.5,5.5,0,0,1,7.48,7.484,5.5,5.5,0,0,1-1.5,10.792h-9.152a5.5,5.5,0,0,0-5.5,5.5v31.081h-2.4V82.443a5.5,5.5,0,0,0-5.5-5.5,5.5,5.5,0,0,1-1.5-10.792Z"
                  transform="translate(-138.717 -43.73)" fill="#f92044" />
                <g data-name="Group 2290" transform="translate(19.431 24.183)">
                  <rect data-name="Rectangle 3474" width="2.397" height="2.397" transform="translate(9.59)"
                    fill="#fff" />
                  <rect data-name="Rectangle 3475" width="2.397" height="2.397" transform="translate(4.793)"
                    fill="#fff" />
                  <rect data-name="Rectangle 3476" width="2.397" height="2.397" transform="translate(0)" fill="#fff" />
                </g>
              </g>
            </svg>
          </div>
          <h3 class="title">Marketing Stratagy</h3>
          <p>Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="service-item mt-30 animated wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="300ms">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="69.996" height="70" viewBox="0 0 69.996 70">
              <g transform="translate(-0.014)">
                <path class="white" data-name="Path 15"
                  d="M31.022,62.02A31.01,31.01,0,1,1,62.03,31.008H59.319a28.3,28.3,0,1,0-28.3,28.3V62.02h0Z"
                  transform="translate(0 0)" fill="#235c51" />
                <g data-name="Group 7" transform="translate(9.764 9.757)">
                  <path data-name="Path 16"
                    d="M92.584,71.368a21.252,21.252,0,1,0,0,42.5v-6.921a14.331,14.331,0,1,1,14.332-14.334h6.931A21.283,21.283,0,0,0,92.584,71.368Z"
                    transform="translate(-71.328 -71.368)" fill="#f92044" stroke="#fff" stroke-width="1.5" />
                  <path data-name="Path 17"
                    d="M187.424,180.007a7.412,7.412,0,1,1-7.413-7.411A7.411,7.411,0,0,1,187.424,180.007Z"
                    transform="translate(-158.753 -158.756)" fill="#f92044" stroke="#fff" stroke-width="1.5" />
                </g>
                <g data-name="Group 8" transform="translate(31.022 31.01)">
                  <path class="white" data-name="Path 18"
                    d="M242.544,265.809l-15.729-38.99L265.8,242.555l-16.183,7.068ZM231.716,231.72l10.929,27.091,4.917-11.248,11.243-4.911Z"
                    transform="translate(-226.815 -226.819)" fill="#235c51" />
                  <rect class="white" data-name="Rectangle 9" width="13.629" height="2.71"
                    transform="translate(30.452 32.37) rotate(-135)" fill="#235c51" />
                </g>
              </g>
            </svg>
          </div>
          <h3 class="title">Digital Marketing</h3>
          <p>Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="service-item mt-30 animated wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="76.473" height="70" viewBox="0 0 76.473 70">
              <g transform="translate(0 -21.668)">
                <path class="white" data-name="Path 10718"
                  d="M38.236,63.548A45.313,45.313,0,0,1,.523,43.39L0,42.608l.523-.782a45.357,45.357,0,0,1,75.426,0l.524.782-.524.782A45.32,45.32,0,0,1,38.236,63.548ZM3.4,42.608a42.543,42.543,0,0,0,69.682,0,42.543,42.543,0,0,0-69.682,0Z"
                  fill="#235c51" />
                <path data-name="Path 10719"
                  d="M196.974,90.273A12.571,12.571,0,1,1,184.4,77.7,12.572,12.572,0,0,1,196.974,90.273Z"
                  transform="translate(-146.167 -47.664)" fill="#f92044" />
                <path class="white" data-name="Path 10720"
                  d="M190.866,121h-2.811a10.1,10.1,0,0,1,2.968-7.177l1.992,1.984A7.309,7.309,0,0,0,190.866,121Z"
                  transform="translate(-159.967 -78.39)" fill="#235c51" />
                <g data-name="Group 2240" transform="translate(17.296 21.668)">
                  <path class="white" data-name="Path 10721"
                    d="M228.013,138.8a4.914,4.914,0,1,1,4.914-4.914A4.92,4.92,0,0,1,228.013,138.8Zm0-7.017a2.1,2.1,0,1,0,2.1,2.1A2.106,2.106,0,0,0,228.013,131.778Z"
                    transform="translate(-207.073 -112.941)" fill="#235c51" />
                  <path class="white" data-name="Path 10722"
                    d="M136.743,63.549a20.941,20.941,0,1,1,20.939-20.94A20.964,20.964,0,0,1,136.743,63.549Zm0-39.07a18.129,18.129,0,1,0,18.128,18.129A18.15,18.15,0,0,0,136.743,24.479Z"
                    transform="translate(-115.802 -21.668)" fill="#235c51" />
                  <rect id="Rectangle_3408" data-name="Rectangle 3408" width="2.811" height="6.59"
                    transform="translate(19.537 40.475)" fill="#235c51" />
                  <path class="white" data-name="Path 10723"
                    d="M225.751,351.713a5.313,5.313,0,0,1-5.312-5.314V327.373h10.625V346.4a5.317,5.317,0,0,1-5.313,5.312Zm-2.5-21.529V346.4a2.5,2.5,0,0,0,5,0V330.184Z"
                    transform="translate(-204.81 -281.713)" fill="#235c51" />
                </g>
              </g>
            </svg>
          </div>
          <h3 class="title">Keyword Research</h3>
          <p>Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<div id="portfolio-scroll" class="portfolio-style-1-area pt-85 pb-100">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-9">
        <div class="section-title text-center">
          
          <h3 class="title">Our Project</h3>
          <p>Our focus is on delivering you the absolute best support guiding you through training and providing.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="project-5-item mt-30">
          <img src="assets/images/project/project-1.png" alt="">
          <div class="project-overlay">
            <h3 class="title">Digital Product Design</h3>
            <span>Artist, Illustration</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="project-5-item mt-30">
          <img src="assets/images/project/project-2.png" alt="">
          <div class="project-overlay">
            <h3 class="title">Digital Product</h3>
            <span>Artist, Illustration</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="project-5-item mt-30">
          <img src="assets/images/project/project-3.png" alt="">
          <div class="project-overlay">
            <h3 class="title">Digital Product </h3>
            <span>Artist, Illustration</span>
          </div>
        </div>
        <div class="project-5-item mt-30">
          <img src="assets/images/project/project-4.png" alt="">
          <div class="project-overlay">
            <h3 class="title">Digital Product </h3>
            <span>Artist, Illustration</span>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="project-5-item mt-30">
          <img src="assets/images/project/project-5.png" alt="">
          <div class="project-overlay">
            <h3 class="title">Digital Product Design</h3>
            <span>Artist, Illustration</span>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="project-5-item mt-30">
              <img src="assets/images/project/project-6.png" alt="">
              <div class="project-overlay">
                <h3 class="title">Digital Product</h3>
                <span>Artist, Illustration</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="project-5-item mt-30">
              <img src="assets/images/project/project-7.png" alt="">
              <div class="project-overlay">
                <h3 class="title">Digital Product </h3>
                <span>Artist, Illustration</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="project-5-item mt-30">
          <img src="assets/images/project/project-8.png" alt="">
          <div class="project-overlay">
            <h3 class="title">Digital Product Design</h3>
            <span>Artist, Illustration</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="team-area gray-bg">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-9">
        <div class="section-title text-center">
          
          <h3 class="title">Our Team</h3>
          <p>Our focus is on delivering you the absolute best support guiding you through training and providing.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row team-active">
      <div class="col-lg-3">
        <div class="team-item mt-40 mb-100">
          <img src="assets/images/team-1.jpg" alt="">
          <div class="team-overlay d-flex align-items-end">
            <div class="content">
              <h4 class="title">Charlotte Emma</h4>
              <span>Product Designer</span>
              <ul>
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="team-item mt-40 mb-100">
          <img src="assets/images/team-2.jpg" alt="">
          <div class="team-overlay d-flex align-items-end">
            <div class="content">
              <h4 class="title">Charlotte Emma</h4>
              <span>Product Designer</span>
              <ul>
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="team-item mt-40 mb-100">
          <img src="assets/images/team-3.jpg" alt="">
          <div class="team-overlay d-flex align-items-end">
            <div class="content">
              <h4 class="title">Charlotte Emma</h4>
              <span>Product Designer</span>
              <ul>
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="team-item mt-40 mb-100">
          <img src="assets/images/team-4.jpg" alt="">
          <div class="team-overlay d-flex align-items-end">
            <div class="content">
              <h4 class="title">Charlotte Emma</h4>
              <span>Product Designer</span>
              <ul>
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="team-item mt-40 mb-100">
          <img src="assets/images/team-5.jpg" alt="">
          <div class="team-overlay d-flex align-items-end">
            <div class="content">
              <h4 class="title">Charlotte Emma</h4>
              <span>Product Designer</span>
              <ul>
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="team-item mt-40 mb-100">
          <img src="assets/images/team-2.jpg" alt="">
          <div class="team-overlay d-flex align-items-end">
            <div class="content">
              <h4 class="title">Charlotte Emma</h4>
              <span>Product Designer</span>
              <ul>
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>