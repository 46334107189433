import { ServicesMainComponent } from './mainpages/services-main/services-main.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './mainpages/home/home.component';
import { ContactComponent } from './mainpages/contact/contact.component';
import { AboutComponent } from './mainpages/about/about.component';
import { PortfolioComponent } from './mainpages/portfolio/portfolio.component';
import { PortfolioDetailsComponent } from './mainpages/portfolio-details/portfolio-details.component';
import { HeaderComponent } from './mainpages/header/header.component';
import { FooterComponent } from './mainpages/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    AboutComponent,
    ServicesMainComponent,
    PortfolioComponent,
    PortfolioDetailsComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
