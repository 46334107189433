import { PortfolioDetailsComponent } from './mainpages/portfolio-details/portfolio-details.component';
import { PortfolioComponent } from './mainpages/portfolio/portfolio.component';
import { ServicesMainComponent } from './mainpages/services-main/services-main.component';
import { ContactComponent } from './mainpages/contact/contact.component';
import { AboutComponent } from './mainpages/about/about.component';
import { HomeComponent } from './mainpages/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  // { path: 'aboutus', component: AboutComponent },
  // { path: 'contact', component: ContactComponent },
  // { path: 'services', component: ServicesMainComponent },
  // { path: 'portfolio', component: PortfolioComponent },
  // { path: 'portofolio-details', component: PortfolioDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
